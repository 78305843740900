// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_BASE_PATH: 'https://mylzdevadmin.appskeeper.in/',
  API_BASE_PATH_2: 'https://mylzdevapi.appskeeper.in',
  API_BASE_PATH_3: 'https://mylzdevevent.appskeeper.in',
  SECRET_KEY: 'eR@a~C5OWN#8Q6xov?&)LF:Tz/n?jM%S}PN7ECIK_vS|dLT~KU"!x:NexkiAy?7',
};




// export const environment = {
//   production: false,
//   API_BASE_PATH: 'https://api.mylz.com/',
//   API_BASE_PATH_2: 'https://api.mylz.com',
//   API_BASE_PATH_3: 'https://api.mylz.com',
//   SECRET_KEY: 'Jlyi1QwKcf7lsS9tdZwbABRNIpSaOrGr',
// };